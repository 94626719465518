import axios from "axios";
import { useRoutes } from "react-router-dom";
import { GetLanguage, GetToken } from "../helpers/AuthHelper";
import Ratings, { RatingType } from "../pages/rating";
import News from "../pages/news";
import Article from "../pages/news/article";
import Tournaments from "../pages/tournaments";
import Settings from "../pages/settings";
import Rules from "../pages/rules";
import Profile from "../pages/profile";
import Players from "../pages/manage/players";
import Games from "../pages/manage/games";
import Schedule from "../pages/schedule";
import RoundRobin from "../pages/standing/roundrobin";
import LiveScore from "../pages/widgets/livescore";
import Widgets from "../pages/widgets";
import Units from "../pages/units";
import News2024_1 from "../pages/news/news2024_1";
import Tournament from "../pages/tournaments/tournament";
import TournamentGroup from "../pages/tournaments/tournamentgroup";
import { Constants } from "../helpers/ConstantHelper";
import Drafting from "../pages/widgets/drafting";
import News2025_1 from "../pages/news/news2025_1.";

(function () {
    let token = GetToken();
    if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
    let language = GetLanguage();
    if (language !== null && language !== undefined) {
        axios.defaults.headers.common["locale"] = language;
    } else {
        axios.defaults.headers.common["locale"] = "uk";
    }
})();

const Routes = () => {
    return useRoutes([
        { path: "/", element: <Ratings ratingType={RatingType._main} /> },
        { path: "news", element: <News /> },
        { path: "rules", element: <Rules /> },
        { path: "news/:id", element: <Article /> },
        
        { path: "ratings", element: <Ratings ratingType={RatingType._main} /> },
        { path: `ratings/${Constants.RatingTypes._1k0pt}`, element: <Ratings ratingType={RatingType._0pt1k} /> },
        { path: `ratings/${Constants.RatingTypes._5k0pt}`, element: <Ratings ratingType={RatingType._0pt5k} /> },
        { path: `ratings/${Constants.RatingTypes._5k10pt}`, element: <Ratings ratingType={RatingType._10pt5k} /> },
        { path: `ratings/${Constants.RatingTypes._5k15pt}`, element: <Ratings ratingType={RatingType._15pt5k} /> },
        { path: `ratings/${Constants.RatingTypes._Mlns0pt}`, element: <Ratings ratingType={RatingType._0ptMlns} /> },
        
        { path: "tournaments", element: <Tournaments /> },
        { path: "tournament/:id", element: <Tournament /> },
        { path: "manage/games", element: <Games /> },
        { path: "manage/players", element: <Players /> },
        { path: "settings", element: <Settings /> },
        { path: "profile/:id", element: <Profile /> },
        { path: "schedule", element: <Schedule /> },
        { path: "standing/roundrobin/:id", element: <RoundRobin /> },
        { path: "widgets/:id", element: <Widgets /> },
        { path: "widgets/livescore/:id", element: <LiveScore /> },
        { path: "widgets/drafting/:id", element: <Drafting /> },
        { path: "units", element: <Units />},
        // temp
        { path: "news/2024-1", element: <News2024_1 /> },
        { path: "news/2025-1", element: <News2025_1 /> },
    ]);
};

export default Routes;
