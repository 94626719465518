import { Card, CardContent, Grid, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Layout from "../../layout/layout";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Strings from "../../components/LocalizedStrings";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

function createData(
    name: string,
    calories: string,
  ) {
    return { name, calories };
}
const schedule = [
    createData(Strings.wc2024_registration, "February 15 - March 7"),
    createData(Strings.wc2024_draw, "March 8"),
    createData(Strings.wc2024_group_stage, "March 9 - 23"),
    createData(Strings.wc2024_playoff, "March 24 - April 6"),
    createData(Strings.wc2024_3rdplace, "April 7"),
    createData(Strings.wc2024_final, "April 8")
  ];

function ScheduleTables() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="customized table">
          {/* <TableHead>
            <TableRow>
              <StyledTableCell>Dessert (100g serving)</StyledTableCell>
              <StyledTableCell align="right">Calories</StyledTableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {schedule.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.calories}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

const format = [
    createData(Strings.wc2024_game, "Cossacks 3"),
    createData(Strings.wc2024_mod, "WCC"),
    createData(Strings.wc2024_teams, "3x3"),
    createData(Strings.wc2024_peace_time, "0 mins"),
    createData(Strings.wc2024_group_stage, "Bo2"),
    createData(Strings.wc2024_playoff, "Bo3"),
    createData(Strings.wc2024_3rdplace, "Bo3"),
    createData(Strings.wc2024_final, "Bo5")
  ];

function FormatTables() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="customized table">
          {/* <TableHead>
            <TableRow>
              <StyledTableCell>Dessert (100g serving)</StyledTableCell>
              <StyledTableCell align="right">Calories</StyledTableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {format.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.calories}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

function GetNationPoolItem(n1: string, n2: string) {
  return (
    <Grid item xs={12} md={3}>
      <Card>
        <CardContent>
          {n1} – {n2}
        </CardContent>
      </Card>
    </Grid>
  )
}
const News2025_1 = () => {

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} textAlign={"center"} mb={5}>
                  <Box
                    component="img"
                    src="https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/cl2025/champions-league-clans-wtc.png"
                    alt="Clans Champions League 2025"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "45%",
                        md: "40%",
                        lg: "35%",
                        xl: "30%",
                      },
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                Join the Clans Champions League 2025 — one of the most thrilling team tournaments in Cossacks 3, inspired by the football Champions League! Three-player teams will compete in a Swiss system, with the top squads advancing to the finals. It’s the perfect chance to represent your clan or form a mixed team if your clan is inactive. Fight for glory, a trophy, and a place in the history of this legendary league!
                </Grid>
                <Grid item xs={12}>
                This tournament is a battle between clans (teams), where every match counts. At the end of the competition, an ELO ranking 📈 will be established based on team performance.
                </Grid>
                <Grid item xs={12} mt={4}>
                  {Strings.wc2024_tournamentdescription}:
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src="https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/cl2025/Clans%2BChampions%2BLeague%2B2025.png"></img>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12} mb={2}>
                            {Strings.wc2024_format}
                        </Grid>
                        <Grid item xs={12}>
                            {FormatTables()}
                        </Grid>
                        <Grid item xs={12} mb={7}>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            {Strings.wc2024_schedule}
                        </Grid>
                        <Grid item xs={12}>
                            {ScheduleTables()} 
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Typography>Additional Rules:</Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      1. Each clan can create a maximum of 3 teams. (Example: CD#1; CD#2; CD#3)
                    </Grid>
                    <Grid item xs={12}>
                      2. Players without a clan/with inactive clans can create a mixed team. (Example: [BRP]Balin+[PR]Vengeance+[PKS]Petru+DDM)
                    </Grid>
                    <Grid item xs={12}>
                      3. Players whose clans have signed up can connect with other players, but no more than 1 player from the same clan can be in one team. (Example: [-UNION-]Fighter+[-H-]Warren+[KGR]Sokullu+[-WN-]Konda)
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Typography>FAQ:</Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      1. What clans are considered inactive?
                    </Grid>
                    <Grid item xs={12} ml={2} fontStyle={"italic"} fontSize={14}>
                      Those with a maximum of 2 active players.
                    </Grid>
                    <Grid item xs={12}>
                      2. Are we planning to organize more 5000 0 3-3 tournaments in this format?
                    </Grid>
                    <Grid item xs={12} ml={2} fontStyle={"italic"} fontSize={14}>
                      Yes, the tournament should be cyclical like the Champions League in Football, although instead of 1 season a year we could play 2.
                    </Grid>
                    <Grid item xs={12}>
                      3. What about maps? In 3-3 there are some poorly balanced maps (gold mines).
                    </Grid>
                    <Grid item xs={12} ml={2} fontStyle={"italic"} fontSize={14}>
                      In the regular stage we play on a random map. From the knockout stage we plan to introduce a modification that removes poorly balanced maps (as much as possible).
                    </Grid>
                    <Grid item xs={12}>
                      4. How about the matches? How many wins are we playing to?
                    </Grid>
                    <Grid item xs={12} ml={2} fontStyle={"italic"} fontSize={14}>
                      In the group stage BO2. Possible results: 1:0; 1:1; 0:1. In the knockout stage BO3. Possible results: 2:0; 2:1; 1:2; 0:2
                    </Grid>
                    <Grid item xs={12}>
                      5. What about nations?
                    </Grid>
                    <Grid item xs={12} ml={2} fontStyle={"italic"} fontSize={14}>
                      Same nation. Randomized for each match.
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                    {Strings.wc2024_features}:
                    <Grid container>
                        <Grid item xs={12}>

                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src="https://static.wikia.nocookie.net/cossacks3_gamepedia_en/images/9/97/Upgrade_frame34.png"></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={Strings.wc2024_peaceofmystery}
                                        secondary={Strings.wc2024_mysterydescription}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src="https://static.wikia.nocookie.net/cossacks3_gamepedia_en/images/4/43/Upgrade_frame7.png"></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={Strings.wc2024_pauseatstart}
                                        secondary={Strings.wc2024_pausedescription}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src="https://static.wikia.nocookie.net/cossacks3_gamepedia_en/images/4/4d/IconPalisade.png"></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={Strings.wc2024_palisadesfeature}
                                        secondary={Strings.wc2024_palisadesfeaturedescription}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src="https://static.wikia.nocookie.net/cossacks3_gamepedia_en/images/c/c1/IconWall3.png"></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={Strings.wc2024_wallsfeature}
                                        secondary={Strings.wc2024_wallsfeaturedescription}
                                    />
                                </ListItem>
                                
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={3}>
                  {Strings.wc2024_modwcc}:
                </Grid>
                <Grid item xs={12} mt={2}>
                  <List dense={true}>
                    <ListItem>
                      <Typography mr={1}>1. {Strings.wc2024_modstep1}.</Typography><Button variant="outlined" href="https://steamcommunity.com/sharedfiles/filedetails/?id=3123019560&searchtext=World%20Cossacks%20Community" target="_blank">{Strings.wc2024_modstep1button}</Button>
                    </ListItem>
                    <ListItem>
                      2. {Strings.wc2024_modwccstep2}.
                    </ListItem>
                    <ListItem>
                      3. {Strings.wc2024_modwccstep3}.                      
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} mt={3}>
                  {Strings.wc2024_poolnations}:
                </Grid>
                {/* <Grid item xs={12} mt={2}>
                  <Grid container spacing={1} textAlign={"center"}>
                    {GetNationPoolItem("Saxony", "Bavaria")}
                    {GetNationPoolItem("Venice", "Prussia")}
                    {GetNationPoolItem("Denmark", "Spain")}
                    {GetNationPoolItem("Portugal", "Piedmont")}
                    {GetNationPoolItem("Scotland", "Austria")}
                    {GetNationPoolItem("Hungary", "Turkey")}
                    {GetNationPoolItem("Switzerland", "France")}
                    {GetNationPoolItem("Poland", "Ukraine")}
                    {GetNationPoolItem("England", "Sweden")}
                    {GetNationPoolItem("Venice", "Piedmont")}
                    {GetNationPoolItem("Portugal", "Austria")}
                    {GetNationPoolItem("Saxony", "Denmark")}
                    {GetNationPoolItem("Spain", "Bavaria")}
                    {GetNationPoolItem("Hungary", "Poland")}
                    {GetNationPoolItem("England", "France")}
                    {GetNationPoolItem("Turkey", "Scotland")}
                    {GetNationPoolItem("Prussia", "Switzerland")}
                    {GetNationPoolItem("Netherlands", "Sweden")}
                    {GetNationPoolItem("Algeria", "Ukraine")}
                    {GetNationPoolItem("Netherlands", "Algeria")}
                  </Grid>
                </Grid> */}
                <Grid item xs={12} mt={4}>
                  {Strings.wc2024_howparticipate} 
                </Grid>
                <Grid item xs={12} mt={1}>
                  <List dense={true}>
                      <ListItem>
                         <Typography pr={1}>1. {Strings.wc2024_howparticipatestep1}</Typography><Button variant="contained" color="success" href="https://discord.com/invite/QZqzGMyjPk" target="_blank">{Strings.wc2024_howparticipatestep1Button}</Button>
                      </ListItem>
                      <ListItem>
                         <Typography pr={1} mt={3}>2. {Strings.wc2024_howtoparticipatestep2}.</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography pr={1} mt={3}>3. {Strings.wc2024_howtoparticipatestep3}.</Typography><img src="https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/wc2024/green_button.png"></img>
                      </ListItem>
                      <ListItem>
                         <Grid container>
                          <Grid item xs={12} md={6}>
                            <Typography pr={1}>4. {Strings.wc2024_howtoparticipatestep4}.</Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <img src="https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/cl2025/Clans%2BChampions%2BLeague%2B2025%2B-%2Bregistration.png"></img>
                          </Grid>
                         </Grid>
                      </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} mt={4}>
                  <Typography>{Strings.wc2024_jointelegram}</Typography><img src="https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/wc2024/telegram.png" width={150}></img>
                </Grid>
            </Grid>
        </Layout>
    );
};
export default News2025_1;