import { Grid, Link } from "@mui/material";
import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Layout from "../../layout/layout";
import { GetTournamentsList } from "../../services/TournamentsService";
import Strings from "../../components/LocalizedStrings";
import DiscreteSliders from "../../components/DiscreteSliders";

const Title = Strings.tournaments_title;

interface TournamentProps {
    id: string,
    name: string,
    description: string,
    imageUrl: string,
    gameType: string,
    config: string | null,
    isPrimary: boolean
}

const Tournaments = () => {
    const [loading, setLoading] = useState(true);
    const [tournaments, setTournaments] = useState<TournamentProps[]>();

    useEffect(() => {
        GetTournamentsList().then((data : TournamentProps[]) => {
            const filteredData = data.reverse().filter(
                (t) => t.isPrimary === true
            );
            setTournaments(filteredData.reverse());
            setLoading(false);
        });
    }, []);

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} textAlign={"center"} mb={5}>
                    <PageTitle text={Title} />
                </Grid>
                <Grid item xs={12}>
                    {loading || tournaments === undefined ? (
                        <p>Loading...</p>
                    ) : (
                        <Grid container spacing={5}>
                            {tournaments.map((props: TournamentProps) => {
                                let url = `/tournament/${encodeURIComponent(props.id)}`;
                                return (
                                    <Grid item xs={12}>
                                        <Link href={url}>{props.name}</Link>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Layout>
    );
};
export default Tournaments;