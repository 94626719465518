import axios from "axios";
import { Constants } from "../helpers/ConstantHelper";
import { GameType, RatingType } from "../pages/rating";
import { RatingPlayerItem } from "../interfaces/RatingPlayer";

export const GetRating = (gameType : GameType, ratingType : RatingType, top : number) => {
    return axios.get(Constants.ApiUrls.rating + `${gameType}/${ratingType}?top=${top}`).then((response) => response.data);
};

export const GetRatingForPlayer = (id : string) => {
    return axios.get(Constants.ApiUrls.rating_player + encodeURIComponent(id)).then((response) => response.data);
}

export const SaveRatingForPlayer = (id : string, ratings : RatingPlayerItem[]) => {
    return axios.post(Constants.ApiUrls.rating_player + encodeURIComponent(id), ratings);
}