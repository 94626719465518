import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../components/Logo";
import Strings from "../components/LocalizedStrings"
import LanguageSwitcher from "../components/LanguageSwitcher";
import { WhoAmI, WhoAmIContext } from "../components/WhoAmIContext"
import { useContext } from "react";
import { Constants } from "../helpers/ConstantHelper";
import SignInButton from "../components/SignInButton";
import BasicMenu, { BasicMenuProps } from "../components/Menu";

var ratings : BasicMenuProps[] = [
    { title: Strings.rating_general, url: `/ratings`, submenu: [] },
    { title: Strings.rating_0pt1k, url: `/ratings/${Constants.RatingTypes._1k0pt}`, submenu: [] },
    { title: Strings.rating_0pt5k, url: `/ratings/${Constants.RatingTypes._5k0pt}`, submenu: [] },
    { title: Strings.rating_10pt5k, url: `/ratings/${Constants.RatingTypes._5k10pt}`, submenu: [] },
    { title: Strings.rating_15pt5k, url: `/ratings/${Constants.RatingTypes._5k15pt}`, submenu: [] },
    // { title: Strings.rating_0ptMlns, url: `/ratings/${Constants.RatingTypes._Mlns0pt}`, submenu: [] },
];

const pages : BasicMenuProps[] = [
    { title: Strings.news, url: "/news", submenu: [] },
    { title: Strings.rules, url: "/rules", submenu: [] },
    { title: Strings.ratings, url: "/ratings", submenu: ratings },
    { title: Strings.tournaments, url: "/tournaments", submenu: [] },
    { title: Strings.schedule, url: "/schedule", submenu: [] },
    { title: Strings.units, url: "/units", submenu: [] }
];

const Header = () => {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const whoAmI = useContext(WhoAmIContext);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(null);
        if (event.currentTarget.dataset.url !== undefined) {
            window.location.href = event.currentTarget.dataset.url;
        }
    };

    function getMenuItem(page : BasicMenuProps) {
        let menuItem;
        switch (page.title)
        {
            case Strings.tournaments:
                menuItem = ``;
                break;
            default:
                menuItem =
                <MenuItem key={page.title} data-url={page.url} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>;
                break;
        }
        return (menuItem);
    }
    
    return (
        <AppBar position="static">
            <Container>
                <Toolbar disableGutters>
                    <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
                        <Logo width="142px" height="40px" />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <BasicMenu key={page.title} title={page.title} url={page.url} submenu={page.submenu} ></BasicMenu>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                        <Logo width="142px" height="40px" />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <BasicMenu key={page.title} title={page.title} url={page.url} submenu={page.submenu} ></BasicMenu>
                        ))}
                    </Box>
                    <Box sx={{
                            "img": {
                            width: 150,
                            height: 30,
                            },
                        }}
                        width={150}
                        pt={1}>
                        <a href="https://www.buymeacoffee.com/wcc_cossacks" target="_blank">
                            <img src="https://img.buymeacoffee.com/button-api/?text=Support WCC&emoji=&slug=wcc_cossacks&button_colour=FFDD00&font_colour=000000&font_family=Inter&outline_colour=000000&coffee_colour=ffffff" />
                        </a>
                    </Box>
                    <Box>
                        <LanguageSwitcher />
                    </Box>
                    <SignInButton whoami={whoAmI} />
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Header;
